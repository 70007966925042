<template>
  <div class="page-with-title">
    <page-title>
      <div class="d-flex align-items-center">
        <div>
          <h1>{{ song.titulo }}</h1>
          <a
            :href="song.url"
            target="_blank"
          >{{ song.url }}</a>
        </div>
        <div class="ml-4 dropdown">
          <button data-toggle="dropdown" class="btn btn-sm btn-secondary">
            <i class="fa fa-cog" />
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item text-secondary"
              href="javascript:void(0)"
              @click="deleteSong"
            >excluir música</a>
          </div>
        </div>
      </div>
    </page-title>
    <form
      method="post"
      class="p-4"
      @submit.prevent="submit"
    >
      <div class="row">
        <div class="col-md-1">
          <label>ID</label>
          <input
            v-model="song.id_musica"
            type="text"
            readonly
            class="form-control"
          >
        </div>
        <div class="col-md-3">
          <label>Titulo</label>
          <input
            v-model="song.titulo"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-md-3">
          <label>Artista</label>
          <auto-complete
            :data="song.artista"
            @selected="changeArtist"
          />
        </div>
        <div class="col-md-2">
          <label>Ano</label>
          <input
            v-model="song.ano"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-md-2">
          <label>Cód. Vídeo</label>
          <input
            v-model="song.cod_video"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-md-1">
          <label>Rank</label>
          <input
            v-model="song.rank"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-md-3 mt-4">
          <label>Compositores</label>
          <input
            v-model="song.compositores"
            type="text"
            class="form-control"
          >
        </div>
        <div class="col-md-2 mt-4">
          <label>Política de adsense</label>
          <select
            v-model="song.flag_removido_direito_autoral"
            class="form-control"
          >
            <option value="N">
              PERMITIDO
            </option>
            <option value="A">
              REMOVIDO
            </option>
          </select>
        </div>
      </div>
      <hr>
      <div
        v-for="lyrics in song.lyrics"
        :key="lyrics.id_letra"
        class="collapse-box mb-3"
      >
        <div
          :data-target="`#lyrics-${lyrics.id_letra}`"
          class="collapse-heading"
          data-toggle="collapse"
        >
          <h2>{{ lyrics.legend }}</h2>
        </div>
        <div
          :id="`lyrics-${lyrics.id_letra}`"
          class="collapse collapse-body"
        >
          <div
            v-if="getTranslatedTitleField(lyrics.cod_tipo)"
            class="mb-3"
          >
            <b>Título</b>
            <input
              v-model="$data.song[getTranslatedTitleField(lyrics.cod_tipo)]"
              type="text"
              class="form-control"
            >
          </div>
          <b>Letra</b>
          <textarea
            v-if="song.lyrics"
            v-model="lyrics.letra"
            rows="20"
            class="form-control"
          />
        </div>
      </div>
      <div class="mt-4 mb-3">
        <button class="btn btn-2 btn-bigger">
          <b>Salvar alterações</b>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import AutoComplete from '@/components/utils/AutoComplete'
  import { getSong, updateSong } from '@/api/songs'
  import SongDeleteModal from '@/components/modals/songs/SongDeleteModal'


  export default {
    components: { PageTitle, AutoComplete },
    data: () => ({
      song: {}
    }),
    computed: {
      getTranslatedTitleField() {
        return function(type) {
          const field = {
            '0': null,
            'A': 'titulo_pt',
            'C': 'titulo_es',
            'B': 'titulo_en'
          }
          return field[type]
        } 
      }
    },
    async created() {
      this.$store.commit('app/SET_LOADING', true)
      try {
        const res = await getSong(this.$route.params.id, {with: 'lyrics'})
        this.song = res.data.data
        this.$store.commit('app/SET_LOADING', false)
      } catch (err) {
        this.$router.push({name: 'songsIndex'})
      }
    },
    methods: {
      submit() {
        updateSong(this.song)
      },
      changeArtist(artist) {
        this.song.artista = artist.artista
        this.song.id_artista = artist.id_artista
      },
      deleteSong () {
        this.$modal.show(SongDeleteModal, {song: this.song}, {height: 'auto'})
      }
    }
  }
</script>